import { useState } from "react";
import "./Servicos.css"

interface Props {
    src: string;
    alt: string;
    titulo: string;
    servicos: string[]
}

export const Servico = (props: Props) => {
    const [active, setActive] = useState<boolean>(false);

    return(
        <>
            <article onClick={toggleActive} className="servico">
                <img src={props.src} alt={props.alt} />
                <h3>{props.titulo}</h3>
            </article>
            <article className={`${active ? "visible" : "invisible"} lista-servico`}>
                <ul>
                    {props.servicos.map((item, idx) => 
                        <li key={idx} className={`${idx % 2 ? "red" : "green"}`}>{item.toUpperCase()}</li>
                    )}
                </ul>
                <button onClick={toggleActive} className="fechar" type="button">X</button>
            </article>
        </>
    )

    function toggleActive() {
        if (active)
            return setActive(false)
        return setActive(true)
    }
}
import skyline from "../../Assets/skyline.jpg";

export const Sobre = () => {
    return (
        <section id="sobre" className="grid">
            <img src={skyline} alt="Vista aérea da cidade de Lisboa" />
            <article className="content">
                <h2>Sobre Nós</h2>
                <hr />
                <br />
                <p>
                    Conte com a experiência de quem passou pelo processo de imigração, legalização
                    e conquista da cidadania portuguesa.
                </p>
                <br />
                <p>
                    Nossa missão é garantir aos recém chegados no país as orientações corretas e a
                    resolução de toda a parte burocrática dessa jornada.
                </p>
                <br />
                <a href="https://wa.me/+351967909001">
                    <button type="button">Fale com nosso Especialista em Imigração</button>
                </a>
            </article>
        </section>
    )
}
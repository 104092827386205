import './App.css';
import { Contato } from './Components/Contato/Contato';
import { Navbar } from './Components/Navbar/Navbar';
import { Servicos } from './Components/Servicos/Servicos';
import { Sobre } from './Components/Sobre/Sobre';

import { Splash } from './Components/Splash/Splash';
import { Visto } from './Components/Visto/Visto';
import { Wapp } from './Components/Wapp/wapp';

function App() {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main>
        <Splash />
        <Sobre />
        <Visto />
        <Servicos />
        <Contato />
      </main>
      <footer>
        <article className="footer">
          <p className="green">Viva Consultoria &#169; 2024. Todos os direitos reservados</p>
        </article>
        <Wapp />
      </footer>
    </>
  );
}

export default App;

import "./Wapp.css"

import logo from "../../Assets/logo.png";

export const Wapp = () => {
    return(
        <a href="https://wa.me/+351967909001" className="cta">
            <img src={logo} alt="Logo da empresa Viva Consultoria" />
            <button type="button">1</button>
        </a>
    )
}
import "./Splash.css";

import seta from "../../Assets/arrow-down.png";
import vivaPortugal from "../../Assets/vivaPortugal.mp4";

export const Splash = () => {
  return (
    <section className="splash">
      <section className="overlay"></section>
      <video preload="auto" muted autoPlay loop>
        <source src={vivaPortugal} type="video/mp4" />
      </video>
      <section className="chamada">
        <h1>
          <p className="green">VOCÊ, A UM PASSO</p>
          <p className="red">DE VIVER NA EUROPA!</p>
        </h1>
      </section>
      <a href="#sobre" className="descida">
        <img className="fa fa-angle-down" src={seta} alt="Ícone de seta para baixo" aria-hidden />
      </a>
    </section>
  )
}
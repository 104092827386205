import "./Servicos.css"

import passaporte from "../../Assets/passaporte.png";
import certificado from "../../Assets/certificado.png";
import consultoria from "../../Assets/consultoria.png";

import { Servico } from "./Servico"

export const Servicos = () => {
    return(
        <section id="servicos">
            <h2>
                <p className="green">Conheça nossos Serviços</p>
            </h2>
            <section className="servicos grid">
                <Servico
                    src={passaporte}
                    alt="Ícone de Passaporte"
                    titulo="Vistos"
                    servicos={["trabalho", "aposentados", "investidores", "empreendedores", "estudante"]} />
                <Servico
                    src={certificado}
                    alt="Ícone de Certificado"
                    titulo="Certidões"
                    servicos={["casamento", "sentença estrangeira", "dupla nacionalidade", "legalização"]} />
                <Servico
                    src={consultoria}
                    alt="Ícone de Consultoria"
                    titulo="Consultoria"
                    servicos={["deportação / expulsão", "consultoria imigratória", "reagrupamento familiar"]} />
            </section>
        </section>
    )
}
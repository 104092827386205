import "./Contato.css";

import duvida from "../../Assets/duvida.png";
import whatsApp from "../../Assets/wapp.png";
import instagram from "../../Assets/insta.png";
import { useState } from "react";

export const Contato = () => {
    const [active, setActive] = useState<boolean>(false);

    return (
        <section id="contato">
            <img className="duvida" src={duvida} alt="Foto de homem em posição de dúvida" />
            <article className="contato">
                <h2>
                    <p className="green">DÚVIDAS?</p>
                    <p className="red">ENTRE EM CONTATO</p>
                    <br />
                    <p className="txt-contato">
                        Seu sonho de morar na Europa não precisa esperar. Comece hoje mesmo! Facilitamos todo o processo para que você viva essa nova experiência sem complicações.
                        <br />
                        <br />
                            <button type="button" onClick={toggleActive}><span>FALE COM NOSSO ESPECIALISTA AGORA</span></button>
                    </p>
                </h2>
                <section className={`${active ? "visible" : "invisible"} social`}>
                    <a href="https://wa.me/+351967909001">
                        <img src={whatsApp} alt="Ícone do whatsapp" />
                    </a>
                    <a href="https://www.instagram.com/vivaportugalassessoria?igsh=ZnAycHd6ZTI5NWk2&utm_source=qr">
                        <img src={instagram} alt="Ícone do instagram" />
                    </a>
                </section>
            </article>
        </section>
    )

    function toggleActive() {
        if (active)
            return setActive(false)

        return setActive(true)
    }
}
import "./Visto.css";

import garota from "../../Assets/garota.png";

export const Visto = () => {
    return (
        <section id="visto">
            <article className="visto">
                <h2>
                    <p className="green">SEU VISTO DE TRABALHO</p>
                    <p className="red">SEM COMPLICAÇÕES</p>
                    <br />
                    <p className="txt">
                        Garanta seu visto de trabalho com agilidade e sem complicações. Simplificamos o processo para você focar no que realmente importa
                        <br />
                        <span className="red">SUA CARREIRA</span>
                    </p>
                </h2>
            <img className="garota_visto" src={garota} alt="Mulher em roupa de trabalho" />
            </article>
        </section>
    )
}